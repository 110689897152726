import type { LinksFunction } from '@remix-run/node';

import { Outlet } from '@remix-run/react';

// import styles from '~/styles/admin.css?url';

// export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export default function Auth() {
  return (
    <section className="flex min-h-screen items-center bg-gray-100 dark:bg-gray-900">
      <Outlet />
    </section>
  );
}
